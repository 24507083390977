<template lang="html">
  <div class="RedeemPage">
    <div class="RedeemPage__LeftPane" v-if="isUser">
      <div class="RedeemPage__LeftPane--Title">
        <h1>¿Cuándo podrás canjear</h1>
        <h2>tus Labs?</h2>
      </div>
      <div class="RedeemPage__LeftPane__Timer">
        <p class="redeemLabsP">Ya puedes canjear tus LABs</p>
        <!-- <div class="RedeemPage__LeftPane__Timer--Title">
          DISPONIBLE EL
        </div>
        <div class="RedeemPage__LeftPane__Timer--Wrapper">
          <div class="RedeemPage__LeftPane__Timer--Container">
            <div class="RedeemPage__LeftPane__Timer--Num">
              {{communityInfo.pointsRedeemDate | formatDay}}
            </div>
            <div class="RedeemPage__LeftPane__Timer--Text">
              DÍA
            </div>
          </div>
          <div class="RedeemPage__LeftPane__Timer--Container">
            <div class="RedeemPage__LeftPane__Timer--Num">
              {{communityInfo.pointsRedeemDate | formatMonth}}
            </div>
            <div class="RedeemPage__LeftPane__Timer--Text">
              MES
            </div>
          </div>
          <div class="RedeemPage__LeftPane__Timer--Container">
            <div class="RedeemPage__LeftPane__Timer--Num">
              {{communityInfo.pointsRedeemDate | formatYear}}
            </div>
            <div class="RedeemPage__LeftPane__Timer--Text">
              AÑO
            </div>
          </div>
        </div> -->
      </div>
      <div>
        <!-- <div class="EditionButton" v-if="isClient && !isEditingDate">
          <Button color="accent" v-on:click="isRedeemEdition('pointsRedeemDate')" class="small-button">Editar fecha</Button>
          <i class="far fa-question-circle tooltip-icon">
            <p class="infoText">Fecha a partir de la cual podrán canjear Labs los miembros.</p>
          </i>
        </div> -->
        <DatePicker v-model="redeemEdition.pointsRedeemDate" lang="es" format="DD-MM-YYYY" type="date" v-if="isEditingDate"></DatePicker>
        <div class="button-group">
          <Button color="accent" v-on:click="saveForm('pointsRedeemDate')" :disabled="disableSubmit(redeemEdition.pointsRedeemDate,'date')" v-if="isEditingDate" class="small-button">{{$t('save')}}</Button>
          <Button v-on:click="isEditingDate = false" v-if="isEditingDate" class="small-button">{{$t('action_cancel')}}</Button>
        </div>
      </div>
      <div class="RedeemPage__LeftPane__Info--Text">
        {{$t('redeem_user_info_text1')}}
        {{$t('redeem_user_info_text2')}}

      </div>
    </div>
    <div class="RedeemPage__RightPane" v-if="isUser">
      <div class="RedeemPage__RightPane--Title">
        <h1>¿Quieres canjear tus LABs?</h1>
      </div>
      <div class="RedeemPage__RightPane--Labs">
        <div class="Labs">
          <div class="Labs--Num">
            <p v-if="isClient">20</p>
            <p v-if="isUser">{{userProfile.points}}</p>
          </div>
          <div class="Labs--Title">
            <p>Labs</p>
          </div>
        </div>
      </div>
      <div class="RedeemPage__RightPane--Info">
        <!-- <div class="EditionButton" v-if="isClient && !isEditingAwards">
          <Button color="accent" v-on:click="isRedeemEdition('pointsRedeemAwards')" class="small-button">Editar premios</Button>
          <i class="far fa-question-circle tooltip-icon">
            <p class="infoText">Información sobre los productos que se pueden canjear y la cantidad de Labs que cuesta ese producto.</p>
          </i>
        </div>
        <p v-if="!isEditingAwards" v-html="communityInfo.pointsRedeemAwards"></p>
        <vue-editor
          v-model="redeemEdition.pointsRedeemAwards"
          v-if="isEditingAwards">
        </vue-editor>
        <div class="button-group">
          <Button color="accent" v-on:click="saveForm('pointsRedeemAwards')" :disabled="disableSubmit(redeemEdition.pointsRedeemAwards,'text')" v-if="isEditingAwards" class="small-button">Guardar</Button>
          <Button v-on:click="isEditingAwards = false" v-if="isEditingAwards" class="small-button">Cancelar</Button>
        </div> -->
        Podrás canjear tus LABs por premios directos y/o participaciones en el gran sorteo final.  La correspondencia de LABs y premios es la siguiente:
        <table>
          <tr>
            <th>9 LABs</th>
            <th>9 LABs</th>
            <th>1 LAB</th>
          </tr>
          <tr>
            <td>1 tarjeta regalo de 40 € de Amazon.</td>
            <td>Pago de 40 € a través de PayPal.</td>
            <td>1 participación para el sorteo final en el que podrás ganar un iPhone XS o uno de los 20 packs de La vida es bella.</td>
          </tr>
        </table>
        <b>Importante:</b> Podrás canjear un máximo de 2 tarjetas regalo o pagos Paypal en el transcurso de la Comunidad, aunque cuentes con más LABs disponibles. En ese caso, los LABs restantes se convertirán en participaciones para el sorteo al finalizar la comunidad
        <div class="RedeemPage__RightPane__Info">
          <div class="RedeemPage__RightPane__Info--Cards">
            <div class="Card" v-on:click="openModal('PAYPAL',9)">
              <img src="@/assets/img/paypal.svg">
              <button type="button" name="button">Canjear</button>
            </div>
            <div class="Card" v-on:click="openModal('AMAZON',9)">
              <img src="@/assets/img/amazon-logo.svg">
              <button type="button" name="button">Canjear</button>
            </div>
          </div>
          <p class="RedeemPage__RightPane__Info--Text">Estos son los premios que podrás conseguir con las participaciones.</p>
          <div class="RedeemPage__RightPane__Info--Awards">
            <div class="Award" v-on:click="openModal('RAFFLE',1)">
              <img src="@/assets/img/iphone-xs.png">
              <img src="@/assets/img/vidabella.png">
              <button type="button" name="button">Canjear</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Modal" v-if="!hideModal">
      <div class="Modal__content">
        <div class="Modal__header">
          <span class="close" v-on:click="closeModal()">&times;</span>
          <h2>{{$t('redeem')}} LABs</h2>
        </div>
        <div class="Modal__body">
          <div class="Modal__body__content">
            <div class="imgs">
              <img src="@/assets/img/paypal.svg" v-if="currentRedeemPoints.type == 'PAYPAL'">
              <img src="@/assets/img/amazon-logo.svg"  v-if="currentRedeemPoints.type == 'AMAZON'">
              <img src="@/assets/img/iphone-xs.png" v-if="currentRedeemPoints.type == 'RAFFLE'">
              <img src="@/assets/img/vidabella.png" v-if="currentRedeemPoints.type == 'RAFFLE'">
            </div>
            <div v-if="currentRedeemPoints.type == 'AMAZON' || currentRedeemPoints.type == 'PAYPAL'">
              <p>Canjea tus LABs por tarjetas {{$filters.formatRedeemType(currentRedeemPoints.type)}} de 40€, 9 LABs equivalen a una tarjeta.</p>
              <p>Actualmente tienes <b>{{userProfile.points}} LABs</b> para canjear.</p>
              <div v-if="userProfile.points >= currentRedeemPoints.min">
                <p>Selecciona cuantas tarjetas quieres canjear:</p>
                <div class="selection">
                  <select v-model="currentRedeemPoints.cards">
                    <option v-for="i in (Math.floor(userProfile.points/9))" :value="i">{{i}} tarjeta<span v-if="i>1">s</span></option>
                  </select>
                  <button type="button" name="button" v-on:click="redeemPoints()">Canjear</button>
                </div>
              </div>
              <p class="text-center" v-if="userProfile.points < currentRedeemPoints.min && !redeemed">No tienes suficientes LABs para canjear este premio</p>
              <div v-if="redeemed" class="text-center">
                <p>¡Enhorabuena!</p>
                <p>Ya has canjeado tus LABs por el premio solicitado.</p>
                <p>¡Disfrútalo!</p>
              </div>
            </div>
            <div v-if="currentRedeemPoints.type == 'RAFFLE'">
              <p>Canjea tus LABs por participaciones en el sorteo de un iPhone XS y uno de los 20 packs de La vida es bella, 1 LAB equivale a una participación.</p>
              <p>Actualmente tienes <b>{{userProfile.points}} LABs</b> para canjear.</p>
              <div v-if="userProfile.points >= currentRedeemPoints.min">
                <p>Selecciona cuantas participaciones quieres canjear:</p>
                <div class="selection">
                  <select v-model="currentRedeemPoints.participations">
                    <option v-for="i in userProfile.points" :value="i">{{i}} participación</option>
                  </select>
                  <button type="button" name="button" v-on:click="redeemPoints()">Canjear</button>
                </div>
              </div>
              <p class="text-center" v-if="userProfile.points < currentRedeemPoints.min && !redeemed">No tienes suficientes LABs para canjear este premio</p>
              <div v-if="redeemed" class="text-center">
                <p>¡Enhorabuena!</p>
                <p>Ya has canjeado tus LABs por el premio solicitado.</p>
                <p>¡Disfrútalo!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isClient" class="Pending">
      <div class="Pending__Selecteds">
        <Button  color="accent" v-on:click="completeAllRedeems()">{{$t('redeem_complete_selected')}}</Button>
      </div>
      <table class="Pending__Table">
        <tr>
          <th>{{$t('action_select')}}</th>
          <th>Usuario</th>
          <th>{{$t('redeem_redeem_type')}}</th>
          <th>{{$t('labs_redeemed')}}</th>
          <th>{{$t('table_action_tab')}}</th>
        </tr>
        <tr v-for="r in pendingRedeems.objects">
          <td class="center">
            <div class="Pending__Table--checkbox">
              <input type="checkbox" :id="'checkbox'+r.identifier" v-model="r.selected"/>
              <label :for="'checkbox'+r.identifier"></label>
            </div>
          </td>
          <td>
            <MemberItem
              :key="r.member.identifier"
              :hideTags="true"
              :member="r.member"/>
          </td>
          <td class="center">
            <p>{{$filters.formatRedeemType(r.redeemType)}}</p>
          </td>
          <td class="center">
            <p class="primaryColor">{{r.points}}</p>
          </td>
          <td class="center">
            <Button color="accent" v-on:click="completeRedeems(r)">{{$t('action_complete')}}</Button>
          </td>
        </tr>
      </table>
      <!-- <Paginate v-if="pendingRedeems.pages > 1"
        v-model="pagination"
        :page-count="pendingRedeems.pages"
        :page-range="3"
        :margin-pages="2"
        :prev-class="'hidden'"
        :next-class="'hidden'"
        :container-class="'pagination'"
        :page-class="'page-item'"
        :click-handler="fetchPendingRedeems">
      </Paginate> -->
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapMutations  } from 'vuex';
import {
  COMMUNITY_FETCH_MEMBERS,
} from '@/store/community/members.module';
import {
  COMMUNITY_UPDATE_REDEEM_INFO,
  COMMUNITY_FETCH_INFO
} from '@/store/community/info.module'
import {
  COMMUNITY_FETCH_PENDING_REDEEMS,
  COMMUNITY_COMPLETE_REDEEM,
  COMMUNITY_ADD_REDEEM
} from '@/store/community/redeem.module'
import {USER_FETCH_PROFILE} from '@/store/user.module'
import store from '@/store';
import Button from '~/components/Button';
import DatePicker from 'vuejs3-datepicker';
import { sameDayDates } from '@/utils';
import Pagination from 'v-pagination-3';
import MemberItem from '@/components/MemberItem.vue';

export default {
  name: 'RedeemPageStatic',

  components:{
    Button,
    DatePicker,
    Pagination,
    MemberItem,
  },

  computed: {
    ...mapGetters([
      'isClient',
      'isModerator',
      'isUser',
      'communityInfo',
      'userProfile',
      'origin',
      'communityMembers'
    ]),
  },
  data() {
    return {
      currentRedeemPoints:{
        type:"",
        cards:1,
        participations:1,
        min :0,
      },
      redeemed: false,
      hideModal:true,
      pendingRedeems:{},
      redeemArray:[],
      pagination:0,
      restantDate:{
        day:0,
        month:0,
        year:0,
      },
      redeemEdition:{
        pointsRedeemAwards:"",
        pointsRedeemDate:"",
      },
      isEditingDate:false,
      isEditingAwards:false,
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'header': 1 }, { 'header': 2 }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'formula'],
        [{ 'direction': 'rtl' }],
        ['clean'],
      ],
    }
  },

  methods: {
    fetchPendingRedeems: async function(page){
      await store.dispatch(COMMUNITY_FETCH_PENDING_REDEEMS,{page:page-1})
      .then((data) => {
        for (let o of data.objects) {
          o.member = this.findMemberById(o.ownerId)
        }
        this.pendingRedeems = data
        $('html,body').animate({scrollTop: $('.Pending').offset().top}, 800);
      })
    },

    findMemberById(id){
      for (let m of this.communityMembers) {
        if (m.identifier == id) return m
      }
    },

    fetchMembers: async function(){
      await store.dispatch(COMMUNITY_FETCH_MEMBERS,{
        order:"recent",
      });
    },

    completeAllRedeems: async function(){
      let data = {
        elPointsTransactionIds:[]
      }
      for (let p of this.pendingRedeems.objects) {
        if (p.selected) data.elPointsTransactionIds.push(p.identifier)
      }
      await store.dispatch(COMMUNITY_COMPLETE_REDEEM,{data:data});
      Swal.fire("",this.$t('redeem_success'),"success")
      this.fetchPendingRedeems(this.pagination);
    },

    completeRedeems: async function(redeem){
      let data = {
        elPointsTransactionIds:[redeem.identifier]
      }
      await store.dispatch(COMMUNITY_COMPLETE_REDEEM,{data:data});
      Swal.fire("",this.$t('redeem_success'),"success")
      this.fetchPendingRedeems(this.pagination);
    },

    openModal(type,min){
      if (!this.isClient) {
        this.currentRedeemPoints.type = type
        this.currentRedeemPoints.min = min
        this.redeemed = false
        this.hideModal = false
      }
    },

    closeModal(){
      this.hideModal = true
      this.redeemed = false
    },

    redeemPoints: async function(){
      let data = {
        points:0,
        redeemType: this.currentRedeemPoints.type
      }
      if (this.currentRedeemPoints.type == 'PAYPAL' || this.currentRedeemPoints.type == 'AMAZON') data.points = this.currentRedeemPoints.cards * 9
      else data.points = this.currentRedeemPoints.participations
      await store.dispatch(COMMUNITY_ADD_REDEEM,{data:data})
      .then((data) => {
        this.redeemed = true
        this.currentRedeemPoints.cards = 1
        this.currentRedeemPoints.participations = 1
        store.dispatch(USER_FETCH_PROFILE)
      })
    },

    isRedeemEdition: function(property){
      if (property == "pointsRedeemAwards") {
        this.isEditingAwards = true
        this.redeemEdition.pointsRedeemAwards = this.communityInfo.pointsRedeemAwards
      }
      else {
        this.isEditingDate = true
        this.redeemEdition.pointsRedeemDate = this.communityInfo.pointsRedeemDate
      }
    },

    disableSubmit: function(text,type){
      if (type == "date") {
        let date = new Date();
        let date2 = new Date(text);
        return text == null || (date2 < date && !sameDayDates(date2,date))
      }
      if (type == 'text') {
        let baseText = text.replace(/<[^>]*>?/g, '')
        return baseText.length < 10
      }
    },

    saveForm: async function(property){
      let data = {}
      if (property == "pointsRedeemAwards") {
        if (this.redeemEdition.pointsRedeemAwards.length < 20) {
          return
        }
        data.pointsRedeemAwards = this.redeemEdition.pointsRedeemAwards
        data.pointsRedeemDate = this.communityInfo.pointsRedeemDate
      }
      else{
        if (this.redeemEdition.pointsRedeemDate == null) {
          return
        }
        data.pointsRedeemAwards = this.communityInfo.pointsRedeemAwards
        data.pointsRedeemDate = this.redeemEdition.pointsRedeemDate
      }
      await store.dispatch(COMMUNITY_UPDATE_REDEEM_INFO,{data:data})
      .then((data) => {
        store.dispatch(COMMUNITY_FETCH_INFO)
        Swal.fire("",this.$t('success_correctly_saved'),"success")
      })

      if (property == "pointsRedeemAwards") {
        this.isEditingAwards = false
      }
      else{
        this.isEditingDate = false
      }
    },
  },
  async mounted(){
    if (this.isClient){
      await this.fetchMembers()
      this.fetchPendingRedeems(1);
    }
  },
}
</script>
<style scoped lang="scss">
.RedeemPage{
  background: white;
  display:flex;
  justify-content: space-between;
  padding:0 15% 20px 15%;
  @media screen and ( max-width: 768px ) {
    padding: 0!important;
    flex-direction:column;
  }
  @media screen and ( max-width: 980px ) {
    padding: 0 5%;
  }
  &__LeftPane{
    width: 30%;
    display:flex;
    flex-direction:column;
    background: #f4f4f4;
    padding: 40px 20px 140px 20px;
    border-bottom: 4px solid #f8b656;
    @media screen and ( max-width: 768px ) {
      width: 100%;
    }
    &--Title{
      color: #f7ac3b;
      text-transform: uppercase;
      h1{
        font-size: 20px;
        font-weight: 500;
      }
      h2{
        font-size: 43px;
        margin-top: -10px;
      }
    }
    &__Timer{
      &--Title{
        font-size:20px;
        font-weight: bold;
      }
      &--Wrapper{
        display:flex;
      }
      &--Container{
        margin-right: 10px;
      }
      &--Num{
        font-size: 40px;
        padding: 0 10px;
        color: var(--primary-color);
        font-weight: bold;
        background: white;
      }
      &--Text{
        font-size: 10px;
        font-weight: bold;
      }
    }
    &__Info{
      &--Text{
        font-size: 12px;
        color: #8a8a8a;
        margin-top:15px;
      }
    }
  }
  &__RightPane{
    width: 60%;
    padding-top: 40px;
    @media screen and ( max-width: 768px ) {
      width: 100%;
      padding: 40px 20px 0 20px;
    }
    &--Title{
      color: #f7ac3b;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
      // font-size: 43px;
    }
    &--Labs{
      display: flex;
      margin: 25px 0;
      .Labs{
        color:white;
        font-weight: bold;
        display:flex;
        flex-wrap:wrap;
        padding:1px;
        text-transform: uppercase;
        &--Num{
          background:white;
          p{
            background: #f8b656;
            font-size: 36px;
            padding: 0px 20px;
            margin-right: 1px;
          }
        }
        &--Title{
          background:white;
          p{
            background: #4f4f4f;
            font-size: 29px;
            display:flex;
            align-items:center;
            padding:0 15px;
            height: 100%;
          }
        }
      }
    }

    &--Info{
      font-size: 12px;
      color: #8a8a8a;
      table{
        border-collapse: collapse;
        margin: 10px 0;
        tr{
          th{
            color:#FFFFFF;
            font-size:15px;
            font-weight:bold;
            background:#f8b656;
            width: 33%;
            border:1px dashed #cfd2d2;
          }
          td{
            border:1px dashed #cfd2d2;
            padding: 5px;
          }
        }
      }
    }
    &__Info{
      margin-top: 30px;
      &--Text{
        font-size: 12px;
        color: #8a8a8a;
        margin:20px 0 10px 0;
      }
      &--Cards{
        display:flex;
        justify-content: space-between;
        margin-top: 30px;
        .Card{
          border: 1px solid lightgray;
          width: 45%;
          height: 120px;
          display:flex;
          justify-content: center;
          cursor: pointer;
          margin-top: 10px;
          position:relative;
          img{
            width: 100%;
            &.disabled{
              filter: grayscale(100%) opacity(30%);
            }
          }
          button{
            position: absolute;
            bottom: 0;
            right: 0;
            text-transform: uppercase;
            cursor: pointer;
            background: #f8b656;
            color:white;
            padding: 5px 10px;
            font-size: 15px;
            border: none;
          }
        }
      }
      &--Awards{
        display:flex;
        justify-content: space-between;
        .Award{
          width: 300px;
          height: 150px;
          display:flex;
          position:relative;
          @media screen and ( max-width: 768px ) {
            width: 80%;
          }

          img{
            width: 50%;
            cursor:pointer;
          }
          button{
            position: absolute;
            bottom: 0;
            right: -70px;
            text-transform: uppercase;
            cursor: pointer;
            background: #f8b656;
            color:white;
            padding: 5px 10px;
            font-size: 15px;
            border: none;
          }
        }
      }
    }
  }
}
.EditionButton{
  display:flex;
  position:relative;
  margin-bottom: 10px;
  .tooltip-icon{
    cursor          : pointer;
    position        : relative;
    font-size       : 12px;
    color: black;
    &::after{
      content: '';
      position: absolute;
      left: 50%;
      width: 12px;
      height: 12px;
      top: -10px;
      transform: translate(-50%,-50%) rotate(45deg);
      background-color: #EEEEEE;
      border: 1px solid;
      border-color: transparent var(--primary-color) var(--primary-color) transparent ;
      box-shadow: 3px 3px 4px rgba(0,0,0,0.5);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.8s;
    }
    &:hover p, &:hover::after{
      visibility: visible;
      opacity: 1;
    }
    .infoText{
      min-width: 200px;
      left: 0;
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      top: -10px;
      transform: translate(-50%, -100%);
      padding: 10px 20px;
      color: #444444;
      background-color: #EEEEEE;
      font-size: 10px;
      border-radius: 8px;
      z-index: 1;
      position: absolute;
      border: 1px solid var(--primary-color);
      box-shadow: 0 1px 4px rgba(0,0,0,0.5);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.8s;
    }
  }
}
.button-group{
  display: flex;
  button{
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.small-button{
  font-size: 11px;
  text-transform: uppercase;
}
.redeemLabsP{
  color: var(--primary-color);
  font-weight: bold;
}
.Modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  &__content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  &__header {
    padding: 6px 16px;
    background-color: #f8b656;
    color: white;
    h2{
      font-weight: 500;
      font-size: 18px;
    }
  }

  &__body {
    padding: 16px;
    font-size: 13px;
    color: #8a8a8a;
    &__content{
      display: flex;
    }
    p{
      margin: 10px 0;
    }
    .imgs{
      padding: 20px;
      img{
        border: 1px solid lightgray;
        width: 150px;
        height: 120px;
        margin-right: 20px;
        padding: 10px;
      }
    }
    .selection{
      display: flex;
      select{
        outline: none;
        border: 1px solid #f8b656;
      }
      button{
        text-transform: uppercase;
        cursor: pointer;
        margin-left: 20px;
        background: #f8b656;
        color:white;
        padding: 5px 10px;
        font-size: 18px;
        border: none;
      }
    }
    .text-center{
      font-size: 15px;
      text-align: justify;
      padding: 10px 0 0 0;
      color: #f8b656;
      font-weight: bold;
    }

  }
  .close {
    color: white;
    float: right;
    font-size: 20px;
    font-weight: bold;
    &:hover{
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  @media screen and ( max-width: 768px ) {
    .Modal__content{
      width: 100%;
    }
  }
  @media screen and ( max-width: 495px ) {
    .Modal__body__content{
      flex-direction: column;
    align-items: center;
    }
  }
}
.Pending{
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items:center;
  padding: 20px 0;
  &__Table{
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    th{
      padding: 10px 0;
    }
    tr{
      border:1px solid lightgray;
    }
    td.center{
      text-align:-webkit-center;
    }
    &--checkbox{
      input{
        display: none;
      }

      label:before{
        content:'';
        background:transparent;
        border: 1px solid gray;
        border-radius: 3px;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        text-align:center;
        vertical-align:middle;
        cursor: pointer;
        font-size: 15px;
        font-family:'Times New Roman';
        color:white;
        transition:0.5s;
      }
      input:checked + label:before{
        content: '\2714';
        color:var(--primary-color);
      }
    }

    p{
      font-weight: bold;
      &.primaryColor{
        color:var(--primary-color);
      }
    }
  }
  &__Selecteds{
    margin:10px 0;
    width:100%;
  }
}

</style>
